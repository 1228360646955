var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarmList" }, [
    _c("div", { staticClass: "alarmBox" }, [
      _vm._m(0),
      _c(
        "ul",
        { staticClass: "nt_box" },
        _vm._l(_vm.messageListInfo, function(item, i) {
          return _c("li", { key: i }, [
            _c("a", [_c("span", [_vm._v(_vm._s(item.title))])]),
            _c("a", { staticClass: "nt_message" }, [
              _vm._v(_vm._s(item.message) + " ")
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.moment(item.created_at).format("YYYY.MM.DD a hh:mm"))
              )
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h3", [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v(" notifications_none ")
        ]),
        _vm._v(" 알림 ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }